.form-container-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 20px;
    width: 88%;
}

.form-wrapper {
    /* width: 100%;
    max-width: 800px; */
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 20px;
    align-self: center;
    border: 1px solid   #D0D0D0;
}

.form-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #232323;
    font-size: 16px;
}

.form-group input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}
.form-g textarea{
    width: 100%;
  height: 94px;
  border-radius: 8px;
  border: 1px solid #D0D0D0;
  padding: 12px 10px 12px 10px;
  margin-bottom: 18px;
}


.form-group input[type="number"] {
    --moz-appearance: textfield;
}

.form-group input[type="number"]::-webkit-inner-spin-button,
.form-group input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.password-container {
    position: relative;
}

 .password-toggle-btn {
    position: absolute;
    right: 10px;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    color: #000000;
} 

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
} 

::placeholder{
    font-size: 14px;
    font-weight: 400;
    line-height:16.94px;
    color: #808080;
}

h3{
    margin-left: 29px;
}



@media (max-width: 768px) {
    .admin-sidebar {
        width: 200px;
    }

    .form-container {
        padding: 10px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .password-toggle-btn {
        right: 5px;
    }

    .submit-btn {
        width: 100%;
        padding: 12px;
    }
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
} 

.submit-btn {
    width: 100%;
    height: 43px;
    padding: 12px 10px 12px 10px;
    background-color: #506CFE;
    border: none;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    cursor: pointer;
   
}

.submit-btn:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}
