

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}
.login-page-container {
    display: flex;
    height: 100vh;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
}

.admin-login-content {
    height: 94vh;
    display: flex;
    width: 97%;
    background-color: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
}

.admin-image-container {
    flex: 1 ;
    background-color:#F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px 0px 0px 32px;
}
::placeholder{
    color:  #808080;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    font-family: 'Inter', sans-serif;

}
.admin-image-container img {
    max-width: 80%;
    object-fit: cover;
}


.login-box {
    flex: 1;
    padding: 40px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo-box {
    font-size: 24px;
    text-align: center;
    color: #232323;
    font-weight: 700;
    font-family: 'Inter', sans-serif;

    
}

.form-group-admin {
    margin-bottom: 20px;
}

.form-group-admin label {
  
    display: block;
    font-weight:500;
    margin-bottom: 5px;
    color:#232323;
    font-size: 16px;
    line-height: 22.36px;
    font-family: 'Inter', sans-serif;

}
.required {
    color: red;
    margin-left:3px;
}

.admin-form{
    margin-left: 14%;
    width: 100%;
}
.form-group-admin input {
    width: 80%;
    padding: 12px 10px 12px 10px;
    border: 1px solid  #D0D0D0 ;
    border-radius: 8px;
    font-size: 16px;
    line-height: 19.36px;
    height: 41px;
    
}

.password-container-admin {
    display: flex;
    align-items: center;
    justify-content: start;
}
.password-container-admin input {
   display: flex;
   justify-content: space-between;
    padding: 12px 10px 12px 10px;
    border: 1px solid  #D0D0D0 ;
    border-radius: 8px;
    font-size: 16px;
    line-height: 19.36px;
    height: 43px;
}

.togglePassword-admin {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right:7.5%;
}

.submit-btn-admin {
    width: 80%;
    padding:12px, 10px, 12px, 10px;
    background-color:#506CFE;
    color:  #FFFFFF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    height: 43px;   
    font-family: 'Inter', sans-serif;

}

.submit-btn-admin:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
}


.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (max-width:800px) {
    .admin-image-container{
        border-radius: 32px;
        height: 90%;
        margin-top: 20px;
    }
    .admin-form {
        width: 140%;
        margin-left: 10px;
    }
    .password-container-admin{
        display: flex;
        justify-content:flex-start;
    }
    .togglePassword-admin{
        display: flex;
        justify-content: end;
        left: 75%;
        padding: 10px;
    }
}
@media screen and (min-width: 300px) and (max-width: 700px) {
    .admin-image-container {
        display: none;
    }
    .login-box{
        padding: 15px;
        border: 1px solid #3d3c3c;
        height: 60%;
        margin-top: 55%;
        
    }
    .admin-form{
        margin: 0px;
        width: 125%;
    }
    /* .togglePassword-admin{
        left: 1px;
    } */
    
    
}
