/* Admin Dashboard Styles */

.admin-dashboard {
    padding: 20px;
  }
  
  .admin-main {
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .admin-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  
  .add-user-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .add-user-btn:hover {
    background-color: #0056b3;
  }
  
  .admin-table-section {
    overflow-x: auto;
  }
  
  .admin-table {
    width: 100%;
    border-collapse: collapse;
    border:1px solid #D0D0D0;
  }
  
  .admin-table th, .admin-table td {
    text-align: left;
    padding: 16px;
    font-weight: 500;
   
  }
  
  .admin-table th {
    background-color: #506CFE;
    color: #ffffff;
    font-weight: bold;
  }
  
  .admin-table th,
  .admin-table td {
    width: 12.5%;
  }
  
  .admin-table tr:nth-child(even) {
    background-color:  #F4F4F4;
  }
  
  .admin-table tr:hover {
    background-color: #e9ecef;
  }
  
  .action-icon {
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .edit-icon {
    color: #28a745;
    margin-right: 15px;
  }
  
  .edit-icon:hover {
    color: #218838;
  }
  
  .delete-icon {
    color: #dc3545;
  }
  
  .delete-icon:hover {
    color: #c82333;
  }
  
  .pagination {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }
  
  .pagination-btn {
    margin: 0 5px;
    padding: 2px 5px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .pagination-btn.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
  }

  