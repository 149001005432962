body,
html {
  margin: 0;
  padding: 0;
  /* height: 100%; */
  font-family: 'Inter', sans-serif;
  

}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ECEFFF;
}

.container {
  max-width: 1200px;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;

}

.row {
  display: flex;
  flex-wrap: wrap;
  /* width: 100%; */
  overflow-x: hidden;

}

.image-container {

  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 500px;
  height: 500px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.form-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: auto;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #232323;
  /* width: 480px; */
  font-size: 24px;
  font-weight: 700;
  line-height: 40.05px;
  font-family: 'Inter', sans-serif;

  

}

.form-inputt {
  height: 456px;

}

.form-group {
  margin-bottom: 15px;
  width: 50%;
  /* width: auto; */
}



.form-group label {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  color: #232323;
  font-family: 'Inter', sans-serif;

}

.form-group input {
  width: 100%;
  padding: 12px 10px 12px 10px;
  border: 1px solid #D0D0D0;
  border-radius: 8px;
  font-size: 16px;
  height: 41px;

}

.form-group textarea {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #D0D0D0;
  padding: 12px 10px 12px 10px;
}

.submit-btn {
  width: 100%;
  height: 43px;
  padding: 12px 10px 12px 10px;
  background-color: #506CFE;
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Inter', sans-serif;

}



.password-container {
  display: flex;
  align-items: center;
}

.password-container input {
  flex: 1;
}

.password-toggle-btn-registration{
  position: absolute;
  right: 2.5%;
  font-size: 13px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  color: #000000;
}

.password-toggle-btn-registration1{
  position: absolute;
  right: 2.5%;
  font-size: 13px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  color: #000000;
}

::placeholder {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .image-container {
    display: none;
  }

  .form-container {
    width: 98%;
    margin: 30px 5px;
    
  }
  .submit-btn{
    margin-top: 10px;
  }
}
@media (min-width: 20px) and (max-width: 426px) {
  
  .form-container {
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: column;
    /* margin-right: 0px; */
    padding-right: 4px;
  }
  
  .form-group {
    width: 100%;
  }
  .form-group textarea {
    width: 100%;
  }
  .submit-btn{
    margin-bottom: 20px;
  }
  .form-group label{
    margin: 0px;
    font-size: 15px;
  }
}

.already-registered {
  margin-top: 10px;
  text-align: center;
}

.already-registered .login-link {
  color: #3399cc;
  text-decoration: none;
}

.already-registered .login-link:hover {
  text-decoration: underline;
}