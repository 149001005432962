body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.not_found{
 width: 100%;  
 height: 100vh;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}

.not_found img{
  width: 30vw;
}


.not-found_btn{
padding: 1rem;
border-radius: 10%;
font-weight:bold;
}