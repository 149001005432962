body,
html {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

.layout-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  height: 100%;
  width: 21%;
  align-items: center;
  padding: 24px;
}

.sidebar-admin {
  border-radius: 16px 0px 0px 16px;
  padding: 48px 24px 48px 24px;
  background-color: #506cfe;
  height: 95vh;
  display: flex;
  flex-direction: column;
}

.logo-container {
  height: 44px;
  line-height: 29.05px;
  color: #ffffff;
  width: 228px;
  font-family: "Inter", sans-serif;
}

.dashboard-hr {
  border: 1px solid #ffffff;
  opacity: 0.8;
  margin-top: 20px;
  /* opacity: 2!important; */
}

.sidebar ul {
  list-style: none;
  width: 228px;
  height: 51px;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  margin-top: 34px;
}

.sidebar ul li a {
  color: #263238;
  text-decoration: none;
  width: 141px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
}

.logout-container {
  margin-top: auto;
  padding: 20px;
  text-align: center;
}

.logout-button {
  color: #000000;
  background-color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.header-container {
  display: flex;
  flex-direction: column;
  width: 79%;
  /* padding-left: 10px; */
  background-color: #ffffff;
}

.logo {
  width: 100px;
  /* Adjust size as needed */
  height: auto;
}

.sidebar ul li {
  margin-top: -2px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  padding-left: 38px;
  margin-top: 3%;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-field {
  border: 1px solid #ced4da;
  padding: 12px 20px 12px 40px;
  border-radius: 50px;
  width: 838px;
  height: 42px;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.user-icon {
  display: flex;
  /* align-items: center; */
  padding-right: 34px;
  /* width: 134px; */
  /* height: 40px; */
}

.user-icon-image {
  /* width: 40px;  */
  height: 35px;
  /* border-radius: 50%; */
  /* border: 2px solid #dee2e6; */
}

.user-icon hr {
  width: 1px;
  background-color: #989898;
  transform: rotate(0deg);
  margin: 0;
  margin-right: 11px;
}

.user-icon-notification {
  width: 19px;
  height: 19px;
  color: #232323;
  margin-top: 7px;
  margin-right: 20px;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
}

.user-icon-search {
  position: absolute;
  width: 19px;
  height: 19px;
  top: 47px;
  margin-left: 15px;
  margin-right: 10px;
  color: #606060;
}
