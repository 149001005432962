.response_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #ECEFFF;
    text-align: center;
    box-sizing: border-box;
}

.response_content {
    background-color: #354ab2;
    padding: 30px;
    color: white;
    width: 100%;
    max-width: 85%;
    height: 80vh;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 24px;
}

.response_content img {
    width: 30%;
}

h1 {
    font-size: 5rem;
    margin-bottom: 15px;
    letter-spacing: 0.6px;
}

p {
    font-size: 18px;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}

.download_btn {
    background-color: #00c4cc;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.download {
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 38px;
}

.download_btn:hover {
    background-color: #00a3a8;
}

/* Media Queries */
@media (max-width: 768px) {
    .response_content {
        padding: 20px;
    }

    h1 {
        font-size: 2rem;
    }

    p {
        font-size: 12px;
        margin-bottom: 15px;
    }

    .download_btn {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .response_content {
        padding: 15px;
    }

    h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 10px;
        margin-bottom: 10px;
    }

    .download_btn {
        padding: 6px 12px;
        font-size: 0.8rem;
    }
}